.video-container {
    position: relative;
    text-align: center;
    color: white;
    
  }
  
  
  .video-frame {
    border-radius: 10px;
    width: 100%;
    height: auto;
    align-items: center;
  }
  
  .video-overlay {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
  }
