@import url('./core/styles/margin.css');
@import url('./core/styles/calendarCard.css');
@import url('./core/styles/buttons.css');
@import url('./core/styles/video.css');
@import url('./core/styles/loginpage.css');
@import url('./core/styles/videoPreview.css');
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:wght@100;200;400;800&display=swap');
body {
  height: 100%;
  width: 100%;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Noto Sans', 'Roboto',
    'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
html{
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}
#root{
  width: 100%;
  height: 100%;
}
.flex-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
}

.admin-container {
  background-color: var( --AliasBackgroundPrimary);
}


.header-content {
  position: sticky;
  top: 0;
  z-index: 10;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-color: white;
  padding-bottom: 10px;
}

.wrapper {
  max-width: 500px;
  margin: 0 auto;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
  gap: 24px;
}

.title {
  font-weight: bold;
  font-size: 28px;
}

.title-preview {
  font-weight: bold;
  font-size: 20px;
  text-align: center;
  font-family: 'Noto Sans';
}

.legend-preview {
  color: #667085;
  font-size: 10px;
  font-weight: 600;
  text-align: center;
  font-family: 'Noto Sans';
}

.legend {
  color: #667085;
  font-size: 14px;
  font-weight: 600;
}

.flex-item {
  display: flex;
  justify-content: center;
  width: 100%;
}

.spacer{
  margin-bottom: 40px;
}
