ul.paginator{
    display: flex;
    flex-direction: row;
    list-style: none;
    align-items: center;
    gap: 12px;
}
.paginator li{
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #101828;
    cursor: pointer;
}
li.previous,
li.next{
    cursor: pointer;
    background: #E4E7EC;
    opacity: 0.4;
    border: 1px solid #98A2B3;
    border-radius: 8px;
    width: 34px;
    height: 34px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.activePage{
    width: 34px;
    height: 34px;
    background: #182141;
    border: 1px solid #182141;
    border-radius: 8px;
    color: white !important;
    display: flex;
    justify-content: center;
    align-items: center;
}
