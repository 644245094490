:root {
  --primary-bg: rgba(44, 62, 80, 1);
  --secondary-bg: rgba(0, 0, 0, 0.9);
}
.div-body {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 50vh;
  margin: 0;
  font-family: serif;
}
.phone-body {
  position: relative;
  width: 350px;
  background: var(--secondary-bg);
  height: 650px;
  border-radius: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 1px 2px 1px black inset, 2px 10px 3px rgba(149, 165, 166, 0.1);
}
.phone-body:before,
.phone-body:after {
  content: '';
  position: absolute;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: var(--primary-bg);
  top: 12px;
}
.phone-body:after {
  top: 25px;
  left: 125px;
  width: 8px;
  height: 8px;
}
.phone-inner {
  position: relative;
  background: white;
  height: 80%;
  width: 95%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  border-radius: 5px;
  overflow-y: scroll;
}
/* Speaker-rect */
.phone-inner:before {
  content: '';
  position: absolute;
  width: 50px;
  height: 7px;
  top: -40px;
  border-radius: 5px;
  background: var(--primary-bg);
}
article {
  background: rgba(255, 255, 255, 0.9);
  border-radius: 5px;
}
