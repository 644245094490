.calendar-container {
    position: relative;
    text-align: center;
    color: white;
    border-radius: 20px;
    width: 100%;
    margin-bottom: 40px;
  }

.calendar-text-container {
    position: absolute;
    height: 100px;
    top: 60%;
    background-color: #002A77;
    width: 100%;
    border-radius: 0 0 20px 20px;
    font-family: 'Noto Sans';
}

.calendar-image{
  border-radius: 20px;
  width: 100%;
}

.calendar-title {
  font-weight: 400;
  font-size: 1rem;
  font-family: 'Noto Sans';
  margin: 10px 15px 0 15px ;
  text-align: left;
}

.calendar-text {
  font-weight: 200;
  font-size: 0.8rem;
  margin: 5px 15px 0 15px ;
  text-align: justify;
  color: #D0D5DD;
}

.calendar-link {
    color: white;
}



