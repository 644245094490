.admin-layout{
    box-sizing: border-box;
    height: 100vh;
    display: flex;
}

.sidebar{
    grid-area:  1 / 1 / 6 / 2;
}
.view{
    overflow: hidden;
    width: 100vw;
    height: 100%;
}
.view-header{
    box-sizing: border-box;
    background: #FFFFFF;
    border: 1px solid #E5E5E5;
    border-radius: 4px;
    padding: 24px 32px;
}
.view-content {
    grid-area: 2 / 2 / 6 / 6;
    height: 100%;
}
.sec-des{
    font-size: 18px !important;
    letter-spacing: 0.03em;
    color: #667085 !important;
}
