
.admin-container {
  display: flex;
  width: 100vw;
  height: 100vh;
}

.admin-child {
  background-color: #002a77;
  display: flex;
  width: 50vw;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.admin-item {
  justify-content: center;
}
.error-msg{
  width: 364px;
}
.login-btn{
  width: 327px !important;
}
