.button-unstyled {
    background-color: transparent;
    border-color: transparent;
  }
  
  .button-connect {
    display: inline-block;
    background: #fde29b;
    border-radius: 6px;
    border: 0px;
    color: #ed1c2a;
    padding: 10px 5px 10px 5px;
    width: 35%;
    font-size: 20px;
  }

  .button-connect-2 {
    display: inline-block;
    background: #fde29b;
    border-radius: 6px;
    border: 0px;
    color: #ed1c2a;
    padding: 10px 5px 10px 5px;
    width: 50%;
    font-size: 10px;
  }

  .button-connect {
    display: flex;
    background: #00a6e2;
    border-radius: 6px;
    border: none;
    font-family: 'Noto Sans';
    color: white;
    padding: 10px;
    font-size: 0.9em;
    align-items: center;
    text-align: center;
    justify-content: center;
    width: 50%;
  }

  .button-get-app {
    background: #182141;
    color: white;
    width: 45%;
    border-radius: 6px;
    padding: 10px 5px 10px 5px;
    border: 0px;
    font-weight: 400;
    font-family: 'Noto Sans';
    margin-bottom: 20px;
  }