.bury-spinner{
    width: 100%;
    height: 100%;
    z-index: 99999;
    background-color: rgba(23, 21, 21, 0.8);
    position: fixed;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 0);
    animation: fadeIn 0.2s ease-in;
}

.bury-spinner:after {
    content: " ";
    display: block;
    width: 64px;
    height: 64px;
    margin: 8px;
    border-radius: 50%;
    border: 6px solid #fff;
    border-color: #fff transparent #fff transparent;
    animation: lds-dual-ring 1.2s linear infinite;
    position: fixed;
    top: 45%;
    left: 50%;
    transform: translate(-50%, 0);
}
@keyframes lds-dual-ring {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
