.prev-container {
  position: relative;
  width: 100%;
}

.prev-image {
  opacity: 1;
  display: block;
  width: auto;
  max-height: 140px ;
  transition: .5s ease;
  backface-visibility: hidden;
  overflow: hidden;
}

.prev-middle {
  transition: .5s ease;
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
}

.prev-container:hover .prev-image {
  opacity: 0.7;
}

.prev-container:hover .prev-middle {
  opacity: 1;
}

.prev-button {
  border: none;
  background-color: transparent;
}
