.truncate{
    /* Solo se debe agregar el width del texto donde se utilice
    para truncar */
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.truncate-multi-line{
    /* Agregar '-webkit-line-clamp: lineas' a texto para truncar por multilinea */
    text-overflow:ellipsis;
    overflow:hidden;
    display: -webkit-box !important;
    -webkit-box-orient: vertical;
    white-space: normal;
}

.calendar-lines{
    -webkit-line-clamp: 2;
}
.border-box{
    box-sizing: border-box;
}

/* Decorations */
.cursor-pointer{ cursor: pointer }
.hide-y-scroll::-webkit-scrollbar { width: 0; }
.hide-y-scroll::-webkit-scrollbar-track { display: none; }
.hide-y-scroll::-webkit-scrollbar-thumb { display: none; }
.overflow-y-auto{overflow-y: auto}

/* width and height */
.full-width{width: 100% !important;}
.full-height{ height: 100% !important;}

/* Text aligns */
.text-left{ text-align: left; }
.text-justify{ text-align: justify }
.text-center{ text-align: center }
.text-right{ text-align: right }

/* Flexbox */
.flex{display: flex}
.justify-content-center{justify-content: center}
.justify-content-between{justify-content: space-between}
.justify-content-end{ justify-content: flex-end }
.align-items-center{align-items: center}
.column-direction{flex-direction: column}
.row-direction{flex-direction: row}
.total-center{
    display: flex;
    justify-content: center;
    align-items: center;
}
.self-center{ align-self: center; }

.gap-md{ gap: 18px }

.ml-1{ margin-left: 10px }
.mb-1{ margin-bottom: 10px }
.mb-2{ margin-bottom: 20px }